import "../require-babel-polyfill.js";
import ReactDOM from "react-dom";
import ThematicsDropdownErb from "../components/shared/ThematicsDropdownErb.react";

function mountComponent() {
  const elements = Array.from(document.getElementsByClassName("theme-react-anchor-thematicsDropdown"));

  (elements || []).forEach(element => {
    const {
      thematics,
      fetch_thematics_endpoint,
      current_thematic_ids,
      ids_filter,
      input_name,
      locale,
      placeholder,
      remove_input_when_empty,
      colored_thematics,
      limit,
      readonly,
      do_not_follow_hierarchy_on_select
    } = JSON.parse(element.dataset.reactProps);

    const props = {
      thematics: JSON.parse(thematics || "[]"),
      currentThematicIds: current_thematic_ids,
      inputName: input_name,
      placeholder,
      removeInputWhenEmpty: remove_input_when_empty,
      fetchThematicsEndpoint: fetch_thematics_endpoint,
      displayOnlyThematicIds: ids_filter,
      coloredThematics: colored_thematics,
      limit,
      isDisabled: readonly,
      doNotFollowHierarchyOnSelect: !!do_not_follow_hierarchy_on_select
    };

    I18n.locale = locale || "en";
    ReactDOM.render(<ThematicsDropdownErb {...props}/>, element);
  });
}

mountComponent();

window.addEventListener("theme-react-anchor-thematics-dropdown-mount", () => {
  mountComponent();
});
